import React, { useState, useReducer, useCallback, useEffect } from "react";

import {
    authenticateLite,
    onAuthenticateSuccess,
    onAuthenticateFailure
} from "../../loginForm/helpers/authenticate";
import { getCookie, saveCookie } from "core-ui/client/react/core/actions/shared/sharedActions";
import LoginForm from "../../loginForm/LoginForm";
import useAuthenticateReducer, { initialState } from "../../loginForm/useAuthenticateReducer";

// We should be able to use the iframes window context to determine the login domain: ex: "proj10.retirementpartner.com"
const LOGIN_DOMAIN = window.location.origin || "https://participant.empower-retirement.com";

const OAuthContainer = () => {
    const [accuCode, setAccuCode] = useState("Empower");
    const [loggingInState, setLoggingInState] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [authentication, dispatch] = useReducer(useAuthenticateReducer, initialState);

    /**
     *   Write isOauth cookie to hold prelogin state to hide header and footer
     */
    useEffect(() => {
        if (!getCookie("isOauthLogin")) {
            saveCookie("isOauthLogin", "true", "/");
        }

        if (String(window.location.href).includes("accu=")) {
            let pos = String(window.location.href).indexOf("accu=");
            pos = pos + 5;
            setAccuCode(String(window.location.href).substring(pos));
        }
    }, []);

    const registerButtonClick = () => {};
    const openAccountClick = () => {};

    /**
     * on click submit
     */
    const handleAuthenticate = useCallback(
        async ({ username, password }) => {
            try {
                const deeplinkParam = "";

                const authData = await authenticateLite({
                    accuCode,
                    deeplinkParam,
                    username,
                    password,
                    setLoggingInState,
                    dispatch
                });

                const href = await onAuthenticateSuccess({
                    accuCode,
                    authData,
                    dispatch,
                    setLoggingInState,
                    setAccuCode
                });

                const redirectUrl = href;

                //  Next line used for testing locally
                //  redirectUrl = "http://localhost:3005" + redirectUrl;

                window.location.assign(redirectUrl);
            } catch (error: any) {
                const href = onAuthenticateFailure({
                    error: error,
                    dispatch,
                    setLoggingInState,
                    setShowLogin,
                    loginDomain: LOGIN_DOMAIN,
                    accuCode
                });

                if (href) {
                    //Redirect to where it needs to go
                    window.location.assign(href);
                    //window.top.location.href = href;
                }
            }
        },
        [accuCode]
    );

    return (
        <div id="oauth-login" data-testid="oauth-login-container" className="container row">
            <LoginForm
                accu={accuCode}
                authentication={authentication}
                authenticateInput={handleAuthenticate}
                loggingIn={loggingInState}
                showLoginUpd={showLogin}
                errorCode={authentication.errorCode}
                errorMessageParams={authentication.errorMessageParams}
                registerButtonClickInput={registerButtonClick}
                openAccountClickInput={openAccountClick}
                loginDomain={LOGIN_DOMAIN}
                from={"oauth"}
            />
        </div>
    );
};

export default OAuthContainer;
