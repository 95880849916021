import React, { useState, useReducer, useEffect, useCallback } from "react";
import {
    authenticateTokenPcap,
    authenticateLite,
    authenticatePart,
    onAuthenticateSuccess,
    onAuthenticateFailure
} from "../helpers/authenticate";
import LoginForm from "../LoginForm";
import useAuthenticateReducer, { initialState } from "../useAuthenticateReducer";
import localBuildConfig from "../../../../../../../build/buildConfig.json";
import redwoodAuthenticateToken from "../../../services/redwoodAuthenticateToken";
import loadFTD from "../helpers/loadFtd";
// We should be able to use the iframes window context to determine the login domain: ex: "proj10.retirementpartner.com"
const LOGIN_DOMAIN = window.location.origin || "https://participant.empower-retirement.com";

export interface LoginFormContainerNativeProps {
    accuCodeIn: string | null;
    fromIn: string;
    isLoginPartIn: boolean | null | undefined;
}

/**
 * Login Container for react only platform
 *    Also used by drupal iframe login page
 * @param param0
 * @returns
 */
const LoginFormContainerNative = ({
    accuCodeIn,
    fromIn,
    isLoginPartIn
}: LoginFormContainerNativeProps) => {
    const [accuCode, setAccuCode] = useState(accuCodeIn || "");
    const [loggingInState, setLoggingInState] = useState(false);
    const [showLogin, setShowLogin] = useState(true);

    const [authentication, dispatch] = useReducer(useAuthenticateReducer, initialState);
    const [pcBaseApiUrl, setPcBaseApiUrl] = useState("");
    const getSubdomain = () => {
        const hostname = window.location.hostname;
        const parts = hostname.split(".");
        if (parts.length > 2) {
            return parts[0];
        }
        return hostname;
    };
    useEffect(() => {
        const subdomain = getSubdomain();
        const multiAuth = localBuildConfig.multiAuth;
        if (subdomain && multiAuth[subdomain]) {
            setPcBaseApiUrl(multiAuth[subdomain]);
        }
    }, []);

    const getParamValue = () => {
        const paramName = "deeplink";
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const deeplinkParam = urlParams.get(paramName);
        return deeplinkParam || "";
    };

    const registerButtonClick = () => {
        setShowLogin(false);
    };

    const openAccountClick = () => {
        setShowLogin(false);
        const href = "/participant/#/articles/MYERIRA/openAnAccount";
        window.location.assign(href);
    };

    /**
     * on click submit
     */
    const handleAuthenticate = useCallback(
        async ({ username, password }) => {
            try {
                const isIframe = fromIn === "iframe";
                const deeplinkParam = getParamValue();
                const partAuthData =
                    !isIframe &&
                    (isLoginPartIn ||
                        accuCode === "Empower" ||
                        accuCode === "FREE" ||
                        accuCode === "MYERIRA")
                        ? await authenticatePart({
                              accuCode,
                              deeplinkParam,
                              username,
                              password,
                              setLoggingInState,
                              dispatch,
                              pcBaseApiUrl
                          })
                        : null;
                let authData;
                const idToken = partAuthData?.idToken;

                if (partAuthData?.authProvider === "EMPOWER") {
                    authData = await authenticateTokenPcap({
                        accuCode,
                        deeplinkParam,
                        idToken,
                        setLoggingInState,
                        dispatch
                    });
                } else if (partAuthData?.authProvider === "REDWOOD") {
                    if (idToken) {
                        const redwoodResponse = await redwoodAuthenticateToken(
                            pcBaseApiUrl,
                            idToken
                        );
                        loadFTD(redwoodResponse);
                    }
                } else {
                    authData = await authenticateLite({
                        accuCode,
                        deeplinkParam,
                        username,
                        password,
                        setLoggingInState,
                        dispatch
                    });
                }

                const href = await onAuthenticateSuccess({
                    accuCode,
                    authData,
                    dispatch,
                    setLoggingInState,
                    setAccuCode
                });

                let appendIframe = "";
                //Redirect to where it needs to go
                if (fromIn === "iframe") {
                    appendIframe = "iframe=true";
                }

                let redirectUrl =
                    deeplinkParam && deeplinkParam !== ""
                        ? href + "?noScope=true&deepLinkParam=" + deeplinkParam
                        : href;

                if (fromIn === "iframe") {
                    if (deeplinkParam && deeplinkParam !== "") {
                        redirectUrl = redirectUrl + "&" + appendIframe;
                    } else {
                        redirectUrl = redirectUrl + "?" + appendIframe;
                    }
                }

                //  Next line used for testing locally
                //  redirectUrl = "http://localhost:3005" + redirectUrl;

                window.location.assign(redirectUrl);
            } catch (error) {
                const href = onAuthenticateFailure({
                    error: error,
                    dispatch,
                    setLoggingInState,
                    setShowLogin,
                    loginDomain: LOGIN_DOMAIN,
                    accuCode
                });

                if (href) {
                    //Redirect to where it needs to go
                    window.location.assign(href);
                }
            }
        },
        [accuCode, fromIn, isLoginPartIn, pcBaseApiUrl]
    );

    const updateStyleClass = {
        color: "white"
    };

    return (
        <div data-testid="login-container">
            <h1 style={updateStyleClass}>Test Landing Login page</h1>

            <LoginForm
                accu={accuCode}
                authentication={authentication}
                authenticateInput={handleAuthenticate}
                loggingIn={loggingInState}
                showLoginUpd={showLogin}
                errorCode={authentication.errorCode}
                errorMessageParams={authentication.errorMessageParams}
                registerButtonClickInput={registerButtonClick}
                openAccountClickInput={openAccountClick}
                loginDomain={LOGIN_DOMAIN}
                from={fromIn}
            />
        </div>
    );
};

export default LoginFormContainerNative;
