import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { VERIFY_CODE } from "../../../routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslations, useDeliveryOptions } from "../../../queries";
import { Accordion } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import createAndDeliverActivationCode from "../../../services/mfa/createAndDeliverActivationCode";
import MFAEvents from "../MFAEvents";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { getAccuCode } from "../../../services/accuCodeService";
import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import CustomToggle from "./CustomToggle";
import { AccordionEventKey, DeliveryOptionsFields } from "./types";
import createAndDeliverActivationCodeByIndId from "../../../services/mfa/createAndDeliverActivationCodeByIndId";
import eventBus from "../../../../utils/setEventBus";
interface DeliveryOption {
    alt: string;
    id: string;
    smsDisclaimer?: boolean;
    title: string;
    url: string;
}

interface DeliveryOptionsTranslations {
    "0000": string;
    MFA_ActivationCodeDeliveryMissingInputException: string;
    alreadyHasCode: string;
    mfaDeliveryOptions: DeliveryOption[];
    mfaDescription: string;
    mfaHeader: string;
    mfaSendCode: string;
    mfaWhereToSendCode: string;
    sendActivationCodeAlert: string;
}

interface MappedDeliverySet {
    [key: string]: string[];
}

type MappedDeliverySetType = MappedDeliverySet | {} | undefined;

interface DeliveryOptionsProps {
    legacyRedirect?: (data: { state: string }, key: string) => void;
    linkingContext?: string;
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

const DEFAULT_VALUES = {
    selectedOption: "",
    selectedValue: ""
};

const STATE_MAP_KEY = "ALL";

const trackTimeLoad = function () {
    const signinTime = localStorage.getItem(PerformanceTrackingKeys.PT_SIGNIN_CLICKED);
    const mfaLoadedTime = new Date().getTime();
    localStorage.removeItem(PerformanceTrackingKeys.PT_MFA_LOADED);
    localStorage.setItem(PerformanceTrackingKeys.PT_MFA_LOADED, `${mfaLoadedTime}`);
    localStorage.setItem(
        PerformanceTrackingKeys.PT_SIGNIN_MFA_DELTA,
        `${mfaLoadedTime - parseInt(signinTime ? signinTime : "")}`
    );
};

const DeliveryOptions = ({
    legacyRedirect,
    location,
    scope,
    linkingContext
}: DeliveryOptionsProps) => {
    const {
        alreadyHasCode,
        mfaDeliveryOptions,
        mfaHeader,
        mfaDescription,
        mfaSendCode,
        mfaWhereToSendCode,
        sendActivationCodeAlert,
        "0000": errorMessage,
        MFA_ActivationCodeDeliveryMissingInputException: mfaFormInvalidMessage
    } = useTranslations<DeliveryOptionsTranslations>();

    const indId = linkingContext?.split(":")[0];
    const dbName = linkingContext?.split(":")[1];
    const {
        data: deliveryOptionsData,
        isLoading: deliveryOptionsLoading,
        isError: deliveryOptionsError
    } = useDeliveryOptions(indId, dbName);

    const navigate = useNavigate();

    const [deepLinkName, setDeepLinkName] = useState("");

    /**
     * Requirement: Selecting 'Resend code' from the verification code entry screen will route the user back to the delivery options selection screen,
     * defaulting to the previously selected delivery method.
     */
    const { state: previouslySelectedOption } = useLocation();

    /**
     * Requirement: If a user only has one method of contact (ex. email and no phone), that method will be the only one to display/selected by default.
     * Below option 'onlyPossibleOptionAndValue' applies to having one delivery method of contact with one associated value (ex. one email on file).
     */
    const onlyPossibleOptionAndValue = deliveryOptionsData?.deliverySet.length === 1 && {
        selectedOption: deliveryOptionsData.deliverySet[0].deliveryType.split(":")[0],
        selectedValue: deliveryOptionsData.deliverySet[0].deliveryType.split(":")[1]
    };

    const mappedDeliverySet: MappedDeliverySetType = deliveryOptionsData?.deliverySet.reduce(
        (prev, delivery) => {
            const [id, value] = delivery.deliveryType.split(":");
            const valuesGroupedById = prev[id] || [];
            valuesGroupedById.push(value);
            prev[id] = [...new Set(valuesGroupedById)];
            return prev;
        },
        {}
    );

    const mappedDeliverySetKeys = mappedDeliverySet && Object.keys(mappedDeliverySet);

    /**
     * Requirement: If a user only has one method of contact (ex. email and no phone), that method will be the only one to display/selected by default.
     * Below option 'onlyPossibleOption' applies to having one delivery method of contact with more than one associated value (ex. two emails on file).
     */
    const onlyPossibleOption = mappedDeliverySetKeys &&
        mappedDeliverySetKeys.length === 1 && {
            selectedOption: mappedDeliverySetKeys[0],
            selectedValue: DEFAULT_VALUES.selectedValue
        };

    const initialValues =
        previouslySelectedOption ||
        onlyPossibleOptionAndValue ||
        onlyPossibleOption ||
        DEFAULT_VALUES;

    const {
        control,
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors, isSubmitting, isValid }
    } = useForm<DeliveryOptionsFields>({
        values: initialValues
    });

    const { selectedOption, selectedValue } = watch();

    /**
     * Set up deep link flow in angular code from new delivery options page
     * @param href
     */
    useEffect(() => {
        trackTimeLoad();

        const extractErrorMessageFromRedirection = (href: string) => {
            if (String(href).includes("noScope")) {
                const start = String(href).indexOf("deepLinkParam") + 14;
                let deepLinkParam = String(href).substring(start);

                if (String(deepLinkParam).indexOf("iframe") !== -1) {
                    const deepLinkName = String(deepLinkParam).split("&");
                    deepLinkParam = deepLinkName[0];
                    setDeepLinkName(deepLinkParam);
                }
            }
        };

        extractErrorMessageFromRedirection(window.location.href);

        if (scope && scope.$root && deepLinkName === "") {
            setDeepLinkName(scope.$root.featureName);
        }
    }, [deepLinkName, scope]);

    useEffect(() => {
        if (deliveryOptionsData?.deliverySet.length === 0) {
            // Route to Experian flow when no delivery options
            if (legacyRedirect) {
                legacyRedirect({ state: "ID_PROOFING_OTP_STATE" }, STATE_MAP_KEY);
            } else {
                /**
                 * TODO: When Experian flow is fully converted to React, we can either use
                 * navigate(ID_PROOF_OTP_VERIFY) here or down below loading block we can include
                 *      if (deliveryOptionsLoading || !deliveryOptionsData) {
                            return <div className="loader"></div>;
                        }

                        if (deliveryOptionsData.deliverySet.length === 0) {
                            return <Navigate to={ID_PROOF_OTP_VERIFY} />;
                        }
                 */
            }
        }
    }, [deliveryOptionsData?.deliverySet.length, legacyRedirect]);

    const handleAccordionSelect = useCallback(
        (id: AccordionEventKey) => {
            setValue("selectedOption", id);
            let newSelectedValue = "";
            if (
                typeof id === "string" &&
                mappedDeliverySet &&
                mappedDeliverySet[id] &&
                mappedDeliverySet[id].length === 1
            ) {
                // Auto select value from accordion dropdown if there is only one available to save the additional click
                newSelectedValue = mappedDeliverySet[id][0];
            }
            setValue("selectedValue", newSelectedValue, { shouldValidate: true });
        },
        [setValue, mappedDeliverySet]
    );

    const updateAngularRoutingState = useCallback(() => {
        /**
         * TODO: Delete this function when Angular is removed. Since we are using the React router
         * to navigate from DeliveryOptions to VerificationCodeEntry, the Angular router state is not updated.
         * If the Angular router state is not updated and we try to route from VerificationCodeEntry
         * to any other Angular component, we encounter a bug where VerificationCodeEntry reloads
         * because the router state is out of sync. Using React router to navigate and updating
         * the Angular router state with this function allows us to conserve the state of the previously
         * selected delivery option without needing to lift it up to the Angular app.
         */
        if (location && scope) {
            location.path(VERIFY_CODE);
            scope.$apply();
        }
    }, [location, scope]);

    const executeCreateAndDeliverActivationCode = useCallback(
        async (formData: DeliveryOptionsFields) => {
            const { selectedOption, selectedValue } = formData;
            const deliveryOption = `${selectedOption}:${selectedValue}`;
            if (indId && dbName) {
                const payload = {
                    deliveryOption,
                    dbName,
                    accu: getAccuCode(),
                    indid: indId
                };
                return createAndDeliverActivationCodeByIndId(payload);
            } else {
                const payload = {
                    deliveryOption,
                    accu: getAccuCode()
                };
                return createAndDeliverActivationCode(payload);
            }
        },
        [indId, dbName]
    );

    const handleSendCode = useCallback(
        async (formData: DeliveryOptionsFields) => {
            try {
                await executeCreateAndDeliverActivationCode(formData);
                navigate(VERIFY_CODE, { state: formData });
                updateAngularRoutingState();
            } catch (error) {
                control.setError("root", { message: errorMessage });
            }
        },
        [
            control,
            errorMessage,
            executeCreateAndDeliverActivationCode,
            navigate,
            updateAngularRoutingState
        ]
    );

    const handleInvalid = useCallback(() => {
        control.setError("root", {
            message: mfaFormInvalidMessage
        });
    }, [control, mfaFormInvalidMessage]);

    const dispatchAmplitudeEvent = useCallback((event: MouseEvent<HTMLElement>) => {
        const { selection } = event.currentTarget.dataset;
        const payload = event.currentTarget.dataset.payload || event.currentTarget.textContent;

        // This if else statements are not ideal. When we move away from GA tags, we should remove this statements.
        if (payload === "Send code") {
            eventBus.dispatch("MFAEvent.send_code_button_clicked_event", event.target);
        } else if (payload === "Already have a code?") {
            eventBus.dispatch("MFAEvent.verify_activation_code_clicked_event", event.target);
        } else {
            eventBus.dispatch(
                "MFAEvent.activation_code_delivery_format_change_event",
                event.target
            );
        }
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: String(selection),
            payload: {
                payload
            }
        });
    }, []);

    // TODO: Delete this function when Angular is removed and replace below click handler with just dispatchAmplitudeEvent
    const handleAlreadyHasCodeClick = useCallback(
        (event: MouseEvent<HTMLAnchorElement>) => {
            dispatchAmplitudeEvent(event);
            updateAngularRoutingState();
        },
        [dispatchAmplitudeEvent, updateAngularRoutingState]
    );

    if (deliveryOptionsLoading) {
        return <div className="loader"></div>;
    }

    return (
        <form
            data-testid="delivery-options"
            className="mfa-container"
            onSubmit={handleSubmit(handleSendCode, handleInvalid)}
        >
            {isSubmitting && <div className="loader"></div>}
            <h2>{mfaHeader}</h2>
            <div className="description">{mfaDescription}</div>
            <div className="description bold">{mfaWhereToSendCode}</div>
            <Accordion
                defaultActiveKey={initialValues.selectedOption}
                onSelect={handleAccordionSelect}
            >
                {mfaDeliveryOptions.map(
                    (option) =>
                        mappedDeliverySet &&
                        mappedDeliverySet[option.id] && (
                            <Accordion.Item eventKey={option.id} key={option.id}>
                                <CustomToggle
                                    eventKey={option.id}
                                    selection={MFAEvents.CLICK}
                                    dispatchAmplitudeEvent={dispatchAmplitudeEvent}
                                    option={option}
                                    register={register}
                                />
                                <Accordion.Body>
                                    {mappedDeliverySet[option.id].map((value: string) => (
                                        <div
                                            key={`${option.id}-${value}`}
                                            className="radio-button-container"
                                        >
                                            <Controller
                                                control={control}
                                                name="selectedValue"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        id={`${option.id}-${value}`}
                                                        type="radio"
                                                        value={value}
                                                        onChange={field.onChange}
                                                        checked={
                                                            selectedOption === option.id &&
                                                            selectedValue === value
                                                        }
                                                    />
                                                )}
                                            />
                                            <label htmlFor={`${option.id}-${value}`}>{value}</label>
                                        </div>
                                    ))}
                                    {option.smsDisclaimer && (
                                        <div
                                            className="sms-disclaimer"
                                            dangerouslySetInnerHTML={{
                                                __html: sendActivationCodeAlert
                                            }}
                                        ></div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                )}
            </Accordion>
            {(deliveryOptionsError || errors?.root?.message) && (
                <div className="error-block" aria-live="polite">
                    {deliveryOptionsError ? errorMessage : errors?.root?.message}
                </div>
            )}
            <div className="button-container">
                <Link
                    to={VERIFY_CODE}
                    data-selection={MFAEvents.LINK}
                    onClick={handleAlreadyHasCodeClick}
                    state={{ deepLinkName }}
                >
                    {alreadyHasCode}
                </Link>
                <button
                    className="btn btn-primary"
                    type="submit"
                    data-selection={MFAEvents.CTA_BUTTON}
                    onClick={dispatchAmplitudeEvent}
                    disabled={!isValid}
                >
                    {mfaSendCode}
                </button>
            </div>
        </form>
    );
};

export default DeliveryOptions;
